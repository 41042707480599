<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5 style="max-width: 175px;" :class="model.id ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ model.id && !model.title ? `${model.id}.` : $t("route.addJournalEntry") || model.id ? model.title && `${model.id}. ${model.title}` : $t('route.addJournalEntry') }}</h5>
            <v-spacer />
            <v-btn
              v-if="accessRight.includes('create') && model.id"
              class="primary--text mx-2"
              outlined
              small
              @click="copyJournalEntry"
              :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            >
              {{ $t("quotation.copy") }}
            </v-btn>
            <v-btn
              v-if="model.id"
              small
              class="mx-2"
              color="primary"
              outlined
              @click="downloadExcelFile"
              :disabled="(journalEntries && journalEntries.length < 0)"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              {{ $t("invoice.excel") }}
            </v-btn>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (isFieldReadable && !saveOnlyCategoryStatus) || (!!model.is_locked && model.user_id !== user.id) ||(accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveJournalEntry"
            >
              {{ $t("journalEntry.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-file-delimited-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("journalEntry.journalEntryDetail") }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            dense
                            :readonly="isFieldReadable"
                            v-model="model.title"
                            :label="$t('journalEntry.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="8" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6 class="ms-2 align-self-center font-weight-regular">
                            {{ $t("journalEntry.listOfJournalEntry") }}
                          </h6>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("journalEntry.no") }}
                                  </th>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("journalEntry.opposite") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("journalEntry.referenceOne") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("journalEntry.referenceTwo") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.referenceThree") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.valueDate") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.details") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.debit") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.credit") }}
                                  </th>
                                  <th :style="{ width: '100px' }">
                                    {{ $t("journalEntry.amount") }}
                                  </th>
                                  <th class="text-center" :style="{ width: '80px' }">
                                    {{ $t("journalEntry.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in journalEntries"
                                  :key="index"
                                  :class="index + 1 == journalEntries.length ? 'newRow' : ''"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :disabled="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.opposite"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :disabled="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.reference_one"
                                      required
                                      :rules="ref1Validation"
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :disabled="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.reference_two"
                                      required
                                      :rules="ref2Validation"
                                    />
                                  </td>
                                  <td>
                                    <v-menu
                                      :ref="`startDateMenu${index}`"
                                      v-model="item.startDateMenu"
                                      :close-on-content-click="false"
                                      :return-value.sync="item.reference_one_date"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          class="date-input"
                                          hide-details="auto"
                                          dense
                                          :disabled="isFieldReadable"
                                          v-model="processedItems[index].formattedStartDate"
                                          :rules="profileById.date_format ? dateValid : dateValid2"
                                          required
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.reference_one_date"
                                        @change="$refs[`startDateMenu${index}`][0].save(item.reference_one_date)"
                                      >
                                        <v-btn
                                          small
                                          class="primary"
                                          @click="$refs[`startDateMenu${index}`][0].save(todayDate())">{{ $t("route.today") }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-menu>
                                  </td>
                                  <td>
                                    <v-menu
                                      :ref="`endDateMenu${index}`"
                                      v-model="item.endDateMenu"
                                      :close-on-content-click="false"
                                      :return-value.sync="item.reference_two_date"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          class="date-input"
                                          hide-details="auto"
                                          dense
                                          :disabled="isFieldReadable"
                                          v-model="processedItems2[index].formattedEndDate"
                                          :rules="profileById.date_format ? dateValid3 : dateValid4"
                                          required
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.reference_two_date"
                                        @change="$refs[`endDateMenu${index}`][0].save(item.reference_two_date)"
                                      >
                                        <v-btn
                                          small
                                          class="primary"
                                          @click="$refs[`endDateMenu${index}`][0].save(todayDate())">{{ $t("route.today") }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-menu>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :disabled="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.document_details"
                                      :rules="documentDetailsRequired"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-combobox
                                      :disabled="isFieldReadable"
                                      dense
                                      autocomplete="new-password"
                                      v-model="item.selectedFromAccountNumber"
                                      :items="accountingCardNumberList"
                                      item-text="book_keeping_number"
                                      item-value="id"
                                      readonly
                                      @change="fromAccountCardNumberChange"
                                      @click="openAccountingDialog('from', index)"
                                      flat
                                      hide-no-data
                                      hide-details="auto"
                                    >
                                      <template v-slot:selection="data">
                                        <span>{{ data.item.book_keeping_number ? `${data.item.book_keeping_number}` : null }}</span>
                                      </template>
                                    </v-combobox>
                                  </td>
                                  <td>
                                    <v-combobox
                                      :disabled="isFieldReadable"
                                      dense
                                      autocomplete="new-password"
                                      v-model="item.selectedToAccountNumber"
                                      :items="accountingCardNumberList"
                                      readonly
                                      item-text="book_keeping_number"
                                      item-value="id"
                                      @change="toAccountCardNumberChange"
                                      @click="openAccountingDialog('to', index)"
                                      flat
                                      hide-no-data
                                      hide-details="auto"
                                    >
                                      <template v-slot:selection="data">
                                        <span>{{ data.item.book_keeping_number ? `${data.item.book_keeping_number}` : null }}</span>
                                      </template>
                                    </v-combobox>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :ref="'amount' + index"
                                      :disabled="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.amount"
                                      :rules="amountRequired"
                                      required
                                      @input="itemCalculation()"
                                    />
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="primary"
                                          small
                                          @click="addRow"
                                        >
                                          <v-icon color="primary">mdi-plus</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="primary"
                                          x-small
                                          class="mt-1"
                                          @click="copyData(index)"
                                        >
                                          <v-icon color="primary">mdi-content-copy</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-start">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                        >
                                          <v-icon color="error">mdi-delete-sweep-outline</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="4">
                              <v-row>
                                <v-col cols="12">
                                  <v-textarea
                                    hide-details="auto"
                                    :readonly="isFieldReadable"
                                    dense
                                    v-model="model.remarks"
                                    :label="$t('journalEntry.remarks')"
                                    :rules="[lessThanValidation($t('journalEntry.remarks'),$t('common.less'),250,model.remarks)]"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row>
                                <v-col cols="12">
                                  <v-btn
                                    color="primary"
                                    small
                                    :loading="loading"
                                    :disabled="!valid || model.totalDifference !== '0.00' || !model.id || isFieldReadable"
                                    @click="closeAndUpdate"
                                  >
                                    {{ $t("journalEntry.closeAndUpdate") }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row>
                                <v-col cols="12">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :disabled="isFieldReadable"
                                    readonly
                                    class="text-caption"
                                  >
                                    <template v-slot:label>
                                      <div>{{ $t("invoice.field.status") }}</div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col cols="3" v-if="accessRight.includes('financial')">
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("accountingCardReport.totalDebitAmount") }}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{ numberWithCommas(model.total_debit) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("accountingCardReport.totalCreditAmount") }}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{ numberWithCommas(model.total_credit) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("journalEntry.totalDifference") }}:
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{ numberWithCommas(model.totalDifference) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
      </v-overlay>
      <AccountingCardDialog @fromAccountCardNumberChange="fromAccountCardNumberChange" @toAccountCardNumberChange="toAccountCardNumberChange" :dialog.sync="accountingCardDialog" :typeAccount="typeAccount" :accountingIndex="accountingIndex" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { changedFormData, parseDateYYYYMMDD, formatDateDDMMYYYY, todayDate } from "@/utils";
import Alert from "@/components/Alert";
import AccountingCardDialog from "@/components/moduleTableDialog/Dialog/AccountingCardDialog";
import exportFromJSON from 'export-from-json';
import { validationMixin } from '@/mixins/validationMixin';

export default {
  name: "JournalEntryAction",
  components: {
    Alert,
    AccountingCardDialog,
  },
  mixins: [validationMixin],
  data() {
    return {
      saveOnlyCategoryStatus: false,
      message: "",
      alert: false,
      loading: false,
      valid: false,
      model: {
        status: 0,
        totalDifference: 0.00,
        total_debit: 0.00,
        total_credit: 0.00
      },
      originModel: {},
      journalEntries: [{
        opposite: null,
        reference_one: null,
        reference_one_date: null,
        reference_two: null,
        reference_two_date: null,
        document_details: null,
        debit_account: null,
        credit_account: null,
        amount: null,
        document_id: 138,
        employee_id: null,
        selectedFromAccountNumber: null,
        selectedToAccountNumber: null,
      }],
      accountingIndex: null,
      dialog: false,
      selectedStatus: 0,
      startDateMenu: false,
      endDateMenu: false,
      processedItems: [{ formattedStartDate: "", reference_one_date: "" }],
      processedItems2: [{ formattedEndDate: "", reference_two_date: "" }],
      accountingCardDialog: false,
      typeAccount: "",
      isProgressCircular: false
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("journalEntry.opposite"), value: "opposite" },
        { text: this.$t("journalEntry.referenceOne"), value: "reference_one" },
        { text: this.$t("journalEntry.referenceTwo"), value: "reference_two" },
        { text: this.$t("journalEntry.referenceThree"), value: "reference_one_date" },
        { text: this.$t("journalEntry.valueDate"), value: "reference_two_date" },
        { text: this.$t("journalEntry.details"), value: "document_details" },
        { text: this.$t("journalEntry.debit"), value: "debit_account" },
        { text: this.$t("journalEntry.credit"), value: "credit_account" },
        { text: this.$t("journalEntry.amount"), value: "amount" },
      ];
    },
    documentDetailsRequired() {
      return [
        (v) => !!v || this.$t("formRules.documentDetailsRequired"),
      ];
    },
    amountRequired() {
      return [
        (v) => !!v || this.$t("formRules.amountRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
      ];
    },
    ref1Validation() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
      ];
    },
    ref2Validation() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
      ];
    },
    statusList() {
      return [
        { name: this.$t("order.field.open"), id: 0 },
        { name: this.$t("order.field.finished"), id: 1 },
      ];
    },
    ...mapGetters({
      journalEntryById: "journalEntry/journalEntryById",
      locale: "locale",
      profileById: "profile/profileById",
      journalEntryQuery: "journalEntry/journalEntryQuery",
      accessRight: "accessRight",
      user: "user",
      accountingCardNumberList: "accountingReport/accountingCardNumberList",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
    journalEntryId() {
      return this.$route.params.id
    },
  },
  watch: {
    journalEntryById() {
      if (Object.keys(this.journalEntryById).length > 0) {
        this.model = Object.assign({}, this.journalEntryById);
        this.selectedStatus = this.model.status;
        this.relatedInvoiceItems = this.model?.relatedInvoices?.filter(
          (item) => item.related_model_id
        );
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    journalEntries: {
      handler(val) {
        this.processedItems = val.map((e) => ({
          ...e,
          formattedStartDate: this.profileById.date_format ? formatDateDDMMYYYY(e.reference_one_date) : e.reference_one_date,
        }));
        this.processedItems2 = val.map((e) => ({
          ...e,
          formattedEndDate: this.profileById.date_format ? formatDateDDMMYYYY(e.reference_two_date) : e.reference_two_date,
        }));
      },
      deep: true,
    },
    profileById() {
      this.processedItems = this.journalEntries.map((e) => ({
        ...e,
        formattedStartDate: this.profileById.date_format ? formatDateDDMMYYYY(e.reference_one_date) : e.reference_one_date,
      }));
      this.processedItems2 = this.journalEntries.map((e) => ({
        ...e,
        formattedEndDate: this.profileById.date_format ? formatDateDDMMYYYY(e.reference_two_date) : e.reference_two_date,
      }));
    },
  },
  async created() {
    const self = this;
    setInterval(self.getNow, 1000);
    // More than one user
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    this.onMounteUpdated();
    this.selectedEmployee = this.user.name;
  },
  beforeDestroy() {
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.dialog = false;
    this.$store.commit("journalEntry/SET_JOURNAL_ENTRY_BY_ID", {});
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    async onMounteUpdated() {
      if (this.$route.params.copiedModel) {
        delete this.$route.params.copiedModel.id;
        delete this.$route.params.copiedModel.date_create;
        delete this.$route.params.copiedModel.date_update;
        delete this.$route.params.copiedModel.totalRows;
        delete this.$route.params.copiedModel.year;
        delete this.$route.params.copiedModel.journalNo;
        delete this.$route.params.copiedModel.time_update;

        this.model = Object.assign({},this.model,this.$route.params.copiedModel);
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.model.status_one = null;
        if (this.model.employee_id) {
          this.selectedEmployee = { id: this.model?.employee?.id, name: this.model?.employee?.name };
          this.model.employee_id = this.model?.employee_id;
        }
        if (this.model.journalEntries.length < 1) {
          this.journalEntries = [{
            opposite: null,
            reference_one: null,
            reference_one_date: null,
            reference_two: null,
            reference_two_date: null,
            document_details: null,
            debit_account: null,
            credit_account: null,
            amount: null,
            document_id: 138,
            employee_id: null,
          }];
        } else {
          this.journalEntries = [];
        }
        this.model.status = 0;
        this.selectedStatus = this.model.status;
        this.model.journalEntries.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          delete element["valueDate"];
          delete element["date"];
          delete element["no"];

          const debitAccountNumber = element.debit_account !== undefined ? element.debit_account : '';
          const creditAccountNumber = element.credit_account !== undefined ? element.credit_account : '';
          this.journalEntries.push(Object.assign({}, { 
            ...element,
            selectedFromAccountNumber: { book_keeping_number: debitAccountNumber },
            selectedToAccountNumber: { book_keeping_number: creditAccountNumber }
          }));
        });
        if (this.model.status === 0) {
          this.$nextTick(() => {
            this.$refs.form.validate();
          });
        }
      } else if (this.$route.params.invoice_id) {
        await this.$store.dispatch("journalEntry/GetJournalEntryById", this.$route.params.invoice_id);
        this.selectedStatus = this.model.status;
        if (this.model.journalEntries.length < 1) {
          this.journalEntries = [{
            opposite: null,
            reference_one: null,
            reference_one_date: null,
            reference_two: null,
            reference_two_date: null,
            document_details: null,
            debit_account: null,
            credit_account: null,
            amount: null,
            document_id: 138,
            employee_id: null,
            id: null
          }];
        } else {
          this.journalEntries = [];
        }
        this.model.journalEntries.forEach((element, index) => {
          delete element["created_at"];
          delete element["updated_at"];

          const debitAccountNumber = element.debit_account !== undefined ? element.debit_account : '';
          const creditAccountNumber = element.credit_account !== undefined ? element.credit_account : '';

          this.journalEntries.push(Object.assign({}, { 
            ...element, 
            selectedFromAccountNumber: { book_keeping_number: debitAccountNumber },
            selectedToAccountNumber: { book_keeping_number: creditAccountNumber },
          }));
          if (this.processedItems[index]) {
            this.processedItems[index].formattedStartDate = element.reference_one_date;
            this.processedItems[index].formattedEndDate = element.reference_two_date;
          }
        });

        this.model.total_debit = this.model.totalDebit
        this.model.total_credit = this.model.totalCredit
        if (this.model.status === 0) {
          this.$refs.form.validate();
        }
        this.$store.dispatch("profile/GetCompanyById");
      }
    },
    openAccountingDialog(type, index) {
      this.accountingIndex = index;
      this.accountingCardDialog = true;
      this.typeAccount = type;
    },
    fromAccountCardNumberChange(item,index) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.journalEntries[index].selectedFromAccountNumber = { book_keeping_number: item?.book_keeping_number }
      } else {
        this.journalEntries[index].selectedFromAccountNumber = null;
      }
      this.itemCalculation();
    },
    toAccountCardNumberChange(item,index) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.journalEntries[index].selectedToAccountNumber = { book_keeping_number: item?.book_keeping_number }
      } else {
        this.journalEntries[index].selectedToAccountNumber = null;
      }
      this.itemCalculation();
    },
    changeCategoryStatus() {
      if (this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "JournalEntry",
        query: {
          ...this.journalEntryQuery,
        },
      });
    },
    copyJournalEntry() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "addJournalEntry",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x ? type === "float" ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : type === "float" ? (0).toFixed(2) : x;
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v || (v && String(v).length <= sizeValue) || this.$t("formRules.lengthValidMessage", { fieldName: fieldValue, type: typeValue, size: sizeValue })
      );
    },
    itemCalculation() {
      let self = this;

      this.model.total_credit = this.journalEntries.reduce((total, item) => {
        if (item?.selectedToAccountNumber?.book_keeping_number) {
          return parseFloat(self.numberFromCommas(item.amount)) ?
            parseFloat(total) + parseFloat(self.numberFromCommas(item.amount)) : total + 0;
        }
        return total;
      }, 0);

      this.model.total_debit = this.journalEntries.reduce((total, item) => {
        if (item?.selectedFromAccountNumber?.book_keeping_number) {
          return parseFloat(self.numberFromCommas(item.amount)) ?
            parseFloat(total) + parseFloat(self.numberFromCommas(item.amount)) : total + 0;
        }
        return total;
      }, 0);

      const debit = parseFloat(this.model.total_debit) || 0;
      const credit = parseFloat(this.model.total_credit) || 0;

      this.model.totalDifference = (debit - credit).toFixed(2);
    },
    getNow() {
      const today = new Date();
      const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.model.reference_two_date = dateTime;
      this.model.reference_one_date = dateTime;
    },
    addRow() {
      this.journalEntries.push({
        index: this.journalEntries.length,
        opposite: null,
        reference_one: null,
        reference_one_date: null,
        reference_two: null,
        reference_two_date: null,
        document_details: null,
        debit_account: null,
        credit_account: null,
        amount: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    copyData(i) {
      delete this.journalEntries[i].id;
      delete this.journalEntries[i].year;
      delete this.journalEntries[i].line_number;
      delete this.journalEntries[i].reference_three;
      delete this.journalEntries[i].reference_three_date;
      delete this.journalEntries[i].debit_account_name;
      delete this.journalEntries[i].credit_account_name;
      delete this.journalEntries[i].employee_id;
      // delete this.journalEntries[i].document_id;
      delete this.journalEntries[i].created_at;
      delete this.journalEntries[i].journal_entry_number_id;
      delete this.journalEntries[i].updated_at;
      delete this.journalEntries[i].employee_name;
      this.journalEntries.push({ ...this.journalEntries[i] });
    },
    deleteRow(index) {
      this.journalEntries.splice(index, 1);
    },
    async closeAndUpdate() {
      if (this.$route.params.invoice_id) {
        this.loading = true;
        this.isProgressCircular = true;
        await this.$store.dispatch("journalEntry/StatusClosedJournalEntry", {
          invoice_id: this.$route.params.invoice_id,
        })
        this.loading = false;
        await this.$router.push('/journal-entry/editJournalEntry/' + this.$route.params.invoice_id).catch(()=>{});
        this.isProgressCircular = false;
      }
    },
    downloadExcelFile() {
      let self = this;
      let data = this.journalEntries.map(o => Object.fromEntries(self.headers.map(k => [k.text, k.value.includes('.')?o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
      data.forEach((el)=> {
      for (let x in el) {
        if (!el[x]) {
          el[x] = ''
        }}
      })
      exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "journalEntry", exportType: exportFromJSON.types.xls})
    },
    async saveJournalEntry() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        this.model.data = this.journalEntries;
        this.$nextTick();
        if (this.selectedStatus && Object.keys(this.selectedStatus).length > 0) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        this.model?.data.forEach((element) => {
          element.debit_account = element?.selectedFromAccountNumber?.book_keeping_number ? Number(element?.selectedFromAccountNumber?.book_keeping_number) : null
          element.credit_account = element?.selectedFromAccountNumber?.book_keeping_number ? Number(element?.selectedToAccountNumber?.book_keeping_number) : null
        })
        if (this.$route.params.invoice_id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          delete saveData.reference_one_date;
          delete saveData.reference_two_date;
          delete saveData.total_credit;
          delete saveData.total_debit;
          delete saveData.totalDifference;
          delete saveData.id;
          
          saveData.data.forEach((element) => {
            ['selectedFromAccountNumber','startDateMenu','endDateMenu','selectedToAccountNumber','valueDate','date','debit_account_name','credit_account_name', 'no','index']
            .forEach(property => delete element[property]);
          })
          data = await this.$store
            .dispatch("journalEntry/EditJournalEntry", {
              invoice_id: this.$route.params.invoice_id,
              journalEntry: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$router.push("/journal-entry/editJournalEntry/" + this.$route.params.invoice_id).catch(() => {});
          }
        } else if (this.$route.params.copiedModel) {
          this.model.data = this.$route.params.copiedModel.journalEntries
          this.model.data.forEach((ele) => {
            delete ele.id;
          })
          let copyModel = this.model;
          copyModel.total_debit = copyModel.totalDebit;
          copyModel.total_credit = copyModel.totalCredit;
          delete copyModel.id;
          delete copyModel.totalCredit;
          delete copyModel.totalDebit;
          delete copyModel.status_one;
          delete copyModel.journalEntries;
          delete copyModel.reference_one_date;
          delete copyModel.reference_two_date;
          delete copyModel.status;
          const copyModel2 = Object.assign({}, copyModel2, copyModel)
          data = await this.$store
            .dispatch("journalEntry/SetJournalEntry", copyModel2)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router.push("/journal-entry/editJournalEntry/" + data.data.id).catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        } else {
          this.model.data = this.journalEntries 
          delete this.model?.id
          this.model?.data.forEach((element) => {
            element.debit_account = element?.selectedFromAccountNumber?.book_keeping_number
            element.credit_account = element?.selectedToAccountNumber?.book_keeping_number
          })
          this.model?.data?.forEach((element) => {
            delete element.startDateMenu;
            delete element.endDateMenu;
            delete element.selectedFromAccountNumber;
            delete element.selectedToAccountNumber;
            delete element.employee_id;
            delete element?.index;
            element.credit_account ? element.credit_account : null
            element.debit_account ? element.debit_account : null
            element.employee_id = this.user.id
            element.year = new Date().getFullYear()
            element.document_id = 138
          })
          delete this.model.total_credit;
          delete this.model.total_debit;
          this.model.year = new Date().getFullYear()
          this.originModel = Object.assign({}, this.originModel, this.model);
          delete this.originModel.reference_one_date;
          delete this.originModel.reference_two_date;
          delete this.originModel.totalDifference;
          data = await this.$store
            .dispatch("journalEntry/SetJournalEntry", this.originModel)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router.push("/journal-entry/editJournalEntry/" + data.data.id).catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        }
      }
    },
  },
};
</script>
